<template>
  <transition>
    <router-view />
  </transition>
</template>

<script>
export default {
  name: "PeopleIndex",
};
</script>

<style>
</style>